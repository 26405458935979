import { isEmpty } from "lodash";

// GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useLayoutEffect, useState} from "react";
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

import { useRefsContext } from '../../../contexts';

const BackgroundSpaghettiEffects = ({ bgSpaW, opacityWrapper, scrollAnimate, isNavigating }) => {
  const { refs, clearRefsMap, refsLoaded } = useRefsContext();

  const [alreadySet, setAlreadySet] = useState(false);

  useIsomorphicLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (!isEmpty(refs) && refsLoaded == true && alreadySet == false) {
      let STs = [];
      refs.forEach(backgroundEffectsWrapper => {
        let data = JSON.parse(backgroundEffectsWrapper.dataset.backgroundeffects);

        let st = ScrollTrigger.create({
          trigger: backgroundEffectsWrapper,
          start: 'top center',
          end: 'top 75%',
          scrub: 1,
          // markers: true,
          onEnter: self => {
            if (data.onEnter.activate == true && !isNavigating.current) {
              scrollAnimate(data.onEnter.options, bgSpaW, opacityWrapper);
            }
          },
          onLeaveBack: self => {
            if (data.onLeaveBack.activate == true && !isNavigating.current) {
              scrollAnimate(data.onLeaveBack.options, bgSpaW, opacityWrapper);
            }
          },
        })

        STs.push(st);
      });


      setAlreadySet(true);

      return (() => {
        if (!isEmpty(STs)) {
          STs.map(st => {
            st.kill();
          })
        }
        clearRefsMap();
      })
    }
  }, [refsLoaded])

  return null
}

export default BackgroundSpaghettiEffects