import { useContext } from "react";

import { PerformanceContext } from "./PerformanceContext";
import { GlobalContext } from "./GlobalContext";
import { LayoutContext } from "./LayoutContext";
import { RefsContext } from "./RefsContext";

export const usePerformanceContext = () => useContext(PerformanceContext);
export const useGlobalContext = () => useContext(GlobalContext);
export const useLayoutContext = () => useContext(LayoutContext);
export const useRefsContext = () => useContext(RefsContext);