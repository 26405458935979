import { useEffect, useLayoutEffect} from "react";
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const SimpleLoadedSolution = ({websiteRef, children}) => {

  useIsomorphicLayoutEffect(() => {
    if (websiteRef.current != null) {
      websiteRef.current.classList.add('loaded');
    }
  }, [])

  return (
    <>{children}</>
  )
}

export default SimpleLoadedSolution