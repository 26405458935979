//context
import { GlobalContextProvider } from '../contexts/GlobalContext'
import { RefsContextProvider } from '../contexts/RefsContext';
//apollo
import { ApolloProvider } from '@apollo/client'
import client from "../src/utils/apollo/client"
//captcha
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//components
import BackgroundSpaghetti from "../src/components/commons/BackgroundSpaghetti";
//styles
import '../src/styles/base.scss'
//page transition
import { gsap } from "gsap";
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { SwitchTransition, Transition } from "react-transition-group-react-18";
import SimpleLoadedSolution from '../src/components/commons/partials/SimpleLoadedSolution';
//externals
import BackgroundSpaghettiEffects from '../src/components/commons/BackgroundSpaghettiEffects';
// Utils
import useVHVariable from '../src/hooks/useVHVVariable';
// temp ignore warning
import "../src/utils/tempIgnoreFetchPrioWarnings.js";
// fonts
import { Montserrat } from 'next/font/google'
import localFont from 'next/font/local'
const montserrat = Montserrat({ subsets: ['latin'], variable: '--font-monserrat' });
const futura = localFont({ src: '../public/fonts/armchairTitleFonts/FuturaMedium.woff2', variable: '--font-futura' });
const avenir = localFont({ src: '../public/fonts/armchairTitleFonts/AvenirRegular.woff2', variable: '--font-avenir' });
const mathilde = localFont({ src: '../public/fonts/armchairTitleFonts/Mathilde.woff2', variable: '--font-mathilde' });
const nellasue = localFont({ src: '../public/fonts/armchairTitleFonts/NellaSue.woff2', variable: '--font-nellasue' });
const sphere = localFont({ src: '../public/fonts/armchairTitleFonts/Sphere.woff2', variable: '--font-sphere' });

function MyApp({ Component, pageProps }) {
  // Ignore Use Layout effect warning
  if (process.env.NODE_ENV === 'development') {
    const originalWarn = console.warn;
    console.warn = function (...args) {
      if (typeof args[0] === 'string' && args[0].includes('useLayoutEffect does nothing on the server')) {
        return;
      }
      originalWarn.call(console, ...args);
    }
  }
  /**
   * Utils
   */
  useVHVariable(); //set une variable css pour la hauteur de l'écran : /CSS/ height: calc(var(--vh, 1vh) * 100);

  const router = useRouter();
  const websiteRef = useRef(null);

  // spaghetti
  const bgSpaW = useRef(null);
  const opacityWrapper = useRef(null);
  const isNavigating = useRef(false);

  const scrollAnimate = (options, bgSpaW, opacityWrapper) => {
    let defaultOptions = {
      bgcolor: 'transparent',
      size: '80%',
      translateX: 0,
      translateY: 0,
      opacity: 0.4,
      repeat: 0,
      yoyo: false,
      duration: 1
    };
    // background
    gsap.to(bgSpaW.current, {
      background: (options?.background == null || options?.background == undefined) ? defaultOptions.bgcolor : options.background,
    });
    if (options?.background == '#FFFFFF') {
      if (!document.body.classList.contains('bck-spa-white')) { document.body.classList.add('bck-spa-white') }
    } else {
      if (document.body.classList.contains('bck-spa-white')) { document.body.classList.remove('bck-spa-white') }
    }

    // others
    if (window.innerWidth >= 768) {
      gsap.to(opacityWrapper.current, {
        width: (options?.size == null || options?.size == undefined) ? defaultOptions.size : options.size,
        height: (options?.size == null || options?.size == undefined) ? defaultOptions.size : options.size,
        opacity: (options?.opacity == null || options?.opacity == undefined) ? defaultOptions.opacity : options.opacity,
        xPercent: (options?.translatex == null || options?.translatex == undefined) ? defaultOptions.translateX : options.translatex,
        yPercent: (options?.translatey == null || options?.translatey == undefined) ? defaultOptions.translateY : options.translatey,
        repeat: (options?.yoyo == null || options?.repeat == undefined) ? defaultOptions.repeat : options.repeat,
        yoyo: (options?.yoyo == null || options?.yoyo == undefined) ? defaultOptions.yoyo : options.yoyo,
        duration: (options?.duration == null || options?.duration == undefined) ? defaultOptions.duration : options.duration,
      });
    } else {
      gsap.to(opacityWrapper.current, {
        opacity: (options?.opacity == null || options?.size == undefined) ? defaultOptions.opacity : options.opacity,
      });
    }
  }

  const onPageEnter = () => {
    isNavigating.current = false;
  }

  const onPageExit = () => {
    isNavigating.current = true;
    if (bgSpaW.current != null && opacityWrapper.current != null) {
      scrollAnimate({
        bgcolor: null,
        size: null,
        translateX: null,
        translateY: null,
        opacity: null,
        duration: null,
        repeat: null,
        yoyo: null
      }, bgSpaW, opacityWrapper)
    }
    if (websiteRef.current != null) {
      websiteRef.current.classList.remove('loaded');
    }
  }

  return (
    <RefsContextProvider>
      <SwitchTransition>
        <Transition
          key={router.asPath} // our route as a key
          in={true}
          appear={true}
          nodeRef={websiteRef}
          onEnter={onPageEnter} // our enter animation
          onExit={onPageExit} // our exit animation
          timeout={{
            enter: 1100,
            exit: 1100,
          }}
        >
          <div className={`${montserrat.variable} ${futura.variable} ${avenir.variable} ${mathilde.variable} ${nellasue.variable} ${sphere.variable}`}>
            <GlobalContextProvider>
              <ApolloProvider client={client}>
                {/* <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTHA_SITE_KEY}
                  scriptProps={{
                    async: false, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: "body", // optional, default to "head", can be "head" or "body",
                    nonce: undefined,
                  }}> */}
                    <SimpleLoadedSolution websiteRef={websiteRef}>
                      <div ref={websiteRef} className="all-website-wrapper">
                        <Component {...pageProps} />
                      </div>
                    </SimpleLoadedSolution>
                {/* </GoogleReCaptchaProvider> */}
              </ApolloProvider>
            </GlobalContextProvider>
            <BackgroundSpaghettiEffects bgSpaW={bgSpaW} opacityWrapper={opacityWrapper}
              scrollAnimate={scrollAnimate} isNavigating={isNavigating} />
          </div>
        </Transition>
      </SwitchTransition>
      <BackgroundSpaghetti
        bgSpaW={bgSpaW} opacityWrapper={opacityWrapper}
        scrollAnimate={scrollAnimate} isNavigating={isNavigating}
      />
    </RefsContextProvider>
  )
}

export default MyApp
