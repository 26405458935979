import { createContext , useState } from "react";

export const GlobalContext = createContext(null);

export const GlobalContextProvider = (props) => {
  const [context, setContext] = useState({
    isSet: false,
    socialMedias: [
      {
        socialMedia: '',
        url: ''
      },
      {
        socialMedia: '',
        url: ''
      },
      {
        socialMedia: '',
        url: ''
      },
      {
        socialMedia: '',
        url: ''
      }
    ],
    links: {
      shop: {
        target: '',
        title: '',
        url: ''
      },
      legals: {
        target: '',
        title: '',
        url: ''
      },
      expTest: {
        target: '',
        title: '',
        url: ''
      },
      contact: {
        target: '',
        title: '',
        url: ''
      },
      privacyPolicy: {
        target: '',
        title: '',
        url: '',
      },
    },
    backgroundAudio: {
      id: '',
      title: '',
      altText: '',
      description: '',
      guid: ''
    },
    blTriptych: {
      title: '',
      parts: [
        {
          smallTitle: '',
          titre: '',
          content: '',
          image: {
            id: '',
            title: '',
            altText: '',
            description: '',
            guid: ''
          }
        },
        {
          smallTitle: '',
          titre: '',
          content: '',
          image: {
            id: '',
            title: '',
            altText: '',
            description: '',
            guid: ''
          }
        },
        {
          smallTitle: '',
          titre: '',
          content: '',
          image: {
            id: '',
            title: '',
            altText: '',
            description: '',
            guid: ''
          }
        }
      ]
    },
    rdvshowroomForm: [],
    locationFormSelectGr: {
      locationTime: [],
      systemsSelect: []
    },
    subjectSelect: []
  });

  return(
    <GlobalContext.Provider
      value={{ context, setContext }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}