import BackgroundSpaghettiShape from "./partials/BackgroundSpaghettiShape";

const BackgroundSpaghetti = ({ bgSpaW, opacityWrapper }) => {

  return (
    <div className="overflow-hidden absolute top-0 left-0 w-full h-full z-[1]">
      <div className={`bg-spa-wrapper fixed top-0 left-0 flex items-center justify-center
        w-[calc(100vw_+_20%)] h-screen -translate-x-[10%]
        lg:w-screen lg:-translate-x-0
      `} ref={bgSpaW}>
        <div className="w-[110%] h-[110%] lg:w-[80%] lg:h-[80%] opacity-40" ref={opacityWrapper}>
          <BackgroundSpaghettiShape />
        </div>
      </div>
    </div>
  )
}

export default BackgroundSpaghetti