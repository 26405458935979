import { createContext , useEffect, useState } from "react";

export const LayoutContext = createContext(null);

export const LayoutContextProvider = (props) => {
  const [headerStatus, setHeaderStatus] = useState('header-normal');
  const [menuStatus, setMenuStatus] = useState('menu-close');
  const [submenuStatus, setSubmenuStatus] = useState('submenu-close');
  const [subsubmenuStatus, setSubsubmenuStatus] = useState('subsubmenu-close');
  const [navStatus, setNavStatus] = useState('all-close');
  const [panelOpen, setPanelOpen] = useState(false);
  const [subPanelOpen, setSubPanelOpen] = useState(false);
  const [reloadFlag, setReloadFlag] = useState('mobile');
  const [thisPanel, setThisPanel] = useState('none');
  const [thisSubPanel, setThisSubPanel] = useState('none');

  const handleResize = () => {
    if (window.innerWidth >= 1024 && reloadFlag == 'mobile') {
      setReloadFlag('desktop');
    }
    if (window.innerWidth < 1024 && reloadFlag == 'desktop') {
      setReloadFlag('mobile');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [reloadFlag]);

  return(
    <LayoutContext.Provider
      value={{
        headerStatus, setHeaderStatus,
        menuStatus, setMenuStatus,
        submenuStatus, setSubmenuStatus,
        subsubmenuStatus, setSubsubmenuStatus,
        navStatus, setNavStatus,
        panelOpen, setPanelOpen,
        subPanelOpen, setSubPanelOpen,
        reloadFlag, setReloadFlag,
        thisPanel, setThisPanel,
        thisSubPanel, setThisSubPanel

      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}