import { createContext, useCallback, useEffect, useRef, useState } from 'react';

export const RefsContext = createContext([]);

export const RefsContextProvider = (props) => {
  const refsMap = useRef(new Map());
  const [refsLoaded, setRefsLoaded] = useState(false);
  const [mapSize, setMapSize] = useState(0);

  const addToRefs = useCallback((id, el) => {
    if (el) {
      refsMap.current.set(id, el);
      setRefsLoaded(false);
      setMapSize(refsMap.current.size);
    }
  }, [refsMap]);

  const removeFromRefs = useCallback((id) => {
    refsMap.current.delete(id);
    setRefsLoaded(false);
    setMapSize(refsMap.current.size);
  }, [refsMap]);

  const clearRefsMap = useCallback(() => {
    refsMap.current.clear()
    setMapSize(0);

    setRefsLoaded(false);
  }, [refsMap]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefsLoaded(true);
    }, 500); // Attend 500ms après la dernière mise à jour de la Map

    return () => clearTimeout(timer);
  }, [mapSize]);

  return <RefsContext.Provider value={{ refs: Array.from(refsMap.current.values()), addToRefs, removeFromRefs, clearRefsMap, refsLoaded}}>{props.children}</RefsContext.Provider>;
};