import { useEffect } from 'react';

const useVHVariable = () => {
  useEffect(() => {
    const updateVHVariable = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateVHVariable();
    window.addEventListener('resize', updateVHVariable);

    return () => window.removeEventListener('resize', updateVHVariable);
  }, []);
};

export default useVHVariable;