// Sauvegarder la méthode console.error d'origine
const originalError = console.error;

console.error = function (message, ...args) {
  // Filtrer les erreurs avec le message spécifique
  if (args[0] == "fetchPriority" || args[0]?.message?.startsWith('The play()')) {
    return;
  }
  // Appeler la méthode d'origine pour les autres erreurs
  originalError.apply(console, [message, ...args]);
};
