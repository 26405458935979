import { useEffect, useRef, useState } from "react";
import Image from 'next/image';

import Lottie from 'lottie-react';
import bgShape from '../../../../public/lottie/test-3.json';

const BackgroundSpaghettiShape = () => {
  const wrapper = useRef(null);
  const lottieRef = useRef(null);
  const [lottieEnabled, setLottieEnabled] = useState(false);

  const frameData = useRef({
    startTime: null,
    lastFrameTime: null,
    secondCounter: 0,
    initiated: false
  });

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setLottieEnabled(true);
      localStorage.setItem("power", "none");
      if (!frameData.current.initiated) {
        frameData.current.initiated = true;
        frameData.current.startTime = performance.now();
        frameData.current.lastFrameTime = frameData.current.startTime;
        let badFrames = 0;
        let badFramesInLastSecond = 0;

        const checkPerformance = () => {
          const now = performance.now();
          const deltaTime = now - frameData.current.lastFrameTime;
          const elapsedTime = now - frameData.current.startTime;

          // Incrémentation du nombre de frames 'mauvaises' dans la dernière seconde
          if (deltaTime > 1000 / 50) { // 50 FPS
            if (elapsedTime - frameData.current.secondCounter > 1000) {
              badFramesInLastSecond = 0;
              frameData.current.secondCounter += 1000;
            } else {
              badFramesInLastSecond++;
            }
            badFrames++;
          }


          frameData.current.lastFrameTime = now;

          // Vérification si on a dépassé la période de test de 5 secondes
          if (elapsedTime > 3000) {
            if (badFramesInLastSecond > 7 || badFrames >= 30) { // Disons 10 frames 'mauvaises' comme seuil
              setLottieEnabled(false);
            }
            if (wrapper.current != null) {
              wrapper.current.classList.remove('lg:opacity-0');
            }
            localStorage.setItem("power", "enough");
          } else {
            requestAnimationFrame(checkPerformance);
          }
        };

        requestAnimationFrame(checkPerformance);
      }

      return () => {
        // Clean-up pour éviter des appels inutiles
        frameData.current.initiated = false;
      };
    }
  }, []);


  useEffect(() => {
    if (lottieRef.current != undefined) {
      lottieRef.current.setSpeed(.5);
    }
  }, []);

  return (
    <div ref={wrapper} className="relative z-20 w-full h-full lg:opacity-0 transition-all duration-300">
      {lottieEnabled ? (
        <Lottie animationData={bgShape} loop={true} className="w-full h-full" lottieRef={lottieRef}/>
      ): (
        <Image loading="eager" src="/images/svgs/background-spaghetti.svg" width={824} height={762} className='w-[80%] xl:w-[50%] h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' alt="illu" />
      )}
    </div>
  )
}

export default BackgroundSpaghettiShape