import React, { createContext, useState, useEffect, useContext } from 'react';

// Crée un Context pour la performance
export const PerformanceContext = createContext(null);

// Crée un Provider qui va utiliser le hook pour détecter le niveau de performance
export const PerformanceContextProvider = ({ children }) => {
  const [level, setLevel] = useState(0); // Commence au niveau le plus bas

  useEffect(() => {
    let frameId;

    const detectPerformance = () => {
      const start = performance.now();

      frameId = requestAnimationFrame(() => {
        const end = performance.now();
        if (end - start < 16) {
          setLevel((prevLevel) => prevLevel + 1); // Augmente le niveau si < 16ms
        } else {
          setLevel((prevLevel) => Math.max(prevLevel - 1, 0)); // Diminue le niveau si > 16ms
        }
      });
    };

    detectPerformance();

    // Nettoie le requestAnimationFrame lors du démontage du composant
    return () => {
      cancelAnimationFrame(frameId);
    };
  }, []);

  return (
    <PerformanceContext.Provider value={{
      level
    }}>
      {children}
    </PerformanceContext.Provider>
  );
};